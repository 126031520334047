<template>
  <div class="is-inline-flex is-relative ml-4">
    <button
      @click="onShowMoreButtonClick"
      class="upAndDownButton"
      :disabled="loading"
    >
      <IconMore class="icon color-13"></IconMore>
    </button>
    <b-message
      class="PopoverActions"
      title="Choose an Action"
      v-model="isActive"
      aria-close-label="Close message"
    >
      <div @click="handleDuplicateClick">
        <button :disabled="loading">
          <IconDuplicate class="icon color-8 is-xsmall mr-1" /> Duplicate
        </button>
      </div>
      <div
        @click="
          () => {
            onShowMoreButtonClick();
            displayIsViewContractSalesManualModal();
          }
        "
      >
        <button :disabled="loading">
          <IconEye class="icon color-8 is-xsmall mr-1" /> View Sales Manuals
        </button>
      </div>
      <div @click="switchTagModal">
        <button :disabled="loading">
          <IconTag class="icon color-8 is-xsmall mr-1" /> Tags
        </button>
      </div>
      <div
        class="is-relative"
        @click="
          () => {
            onShowMoreButtonClick();
            openImportServiceModal();
          }
        "
      >
        <button :disabled="loading">
          <b-icon
            size="is-small"
            custom-size="mdi-24px"
            icon="swap-vertical-bold"
            class="mr-1 color-8"
          ></b-icon>
          Import new Service
        </button>
        <b-tag
          type="is-info"
          class="px-2"
          style="
            position: absolute;
            top: -0.5rem;
            right: -1rem;
            height: fit-content;
          "
        >
          <p>BETA</p>
        </b-tag>
      </div>
    </b-message>
    <ImportNewServiceModal
      :isModalOpen="isImportServiceModalOpen"
      :closeModal="closeImportServiceModal"
      :onContinue="importNewService"
    />
  </div>
</template>
<script>
import { format } from "date-fns";
import { DATE_FORMAT_YYYY_MM_DD } from "@/constants";
import { PATH_INFO } from "@/router/path";
import ImportNewServiceModal from "@/components/quote-views/_shared/ImportNewServiceModal.vue";

export default {
  name: "MainItineraryActions",
  components: {
    ImportNewServiceModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    itinerary: {
      type: Object,
      default: () => {},
    },
    switchTagModal: {
      type: Function,
      default: () => {},
    },
    loadPricesItinerary: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isActive: false,
      isImportServiceModalOpen: false,
    };
  },
  methods: {
    closeImportServiceModal() {
      this.isImportServiceModalOpen = false;
    },
    openImportServiceModal() {
      this.isImportServiceModalOpen = true;
    },
    async importNewService(serviceCode = "") {
      try {
        this.closeImportServiceModal();
        this.setLoading(true);
        this.setLoadingText("Importing a new Service");
        await this.$store.dispatch("services/importNewService", {
          itineraryId: this.itinerary.data.id,
          services: [serviceCode],
        });
        this.$buefy.toast.open({
          duration: 5000,
          message: `The import of the service ${serviceCode} is in progress`,
          position: "is-top",
          type: "is-success",
        });
        this.setLoadingText("Loading content, please wait...");
        this.setLoading(false);
      } catch (error) {
        this.setLoading(false);
        this.$buefy.snackbar.open({
          duration: 30000,
          message: error.response?.data?.message || "",
          type: "is-danger",
          cancelText: "OK",
          actionText: "",
          dangerouslyUseHTMLString: true,
        });
      }
    },
    displayIsViewContractSalesManualModal() {
      this.$store.dispatch("utils/setIsViewContractSalesManualModalOpen", true);
    },
    setLoading(value = false) {
      this.$store.dispatch("utils/setLoading", value);
      if (!value) {
        this.setLoadingText("Loading content, please wait...");
      }
    },
    setLoadingText(value) {
      this.$store.dispatch("utils/setLoadingText", value);
    },
    onShowMoreButtonClick() {
      this.isActive = !this.isActive;
    },
    handleDuplicateClick() {
      this.$store.dispatch("itinerary/resetItineraryAndRelatedData");
      this.handleDuplicate();
    },
    async handleDuplicate() {
      return await this.$store.dispatch("utils/validateFunctionExecution", {
        functionToExecute: async () => {
          return await this.duplicateItinerary(this.itinerary.data);
        },
        functionToExecuteOnFail: async (error) => {
          this.setLoading(false);
          this.$buefy.toast.open({
            duration: 5000,
            message:
              "Error trying to duplicate the itinerary, please try again.",
            position: "is-top",
            type: "is-danger",
          });
          throw error;
        },
      });
    },
    async duplicateItinerary(data) {
      const payload = {
        code: data.code,
        uuid: data.id,
        start_date: format(data.start_date, DATE_FORMAT_YYYY_MM_DD),
        name: data.name,
      };
      this.setLoading(true);
      this.isActive = false;
      const response = await this.$store.dispatch(
        "itinerary/duplicate",
        payload
      );
      this.$buefy.toast.open({
        duration: 5000,
        message: response.message,
        position: "is-top",
        type: "is-success",
      });
      this.$router.push({
        path: PATH_INFO.BASIC_INFO.path,
        query: { itinerary: response.itinerary },
      });
      this.loadPricesItinerary({ reload: true });
      this.setLoading(false);
    },
  },
};
</script>
<style scoped>
.PopoverActions {
  width: 14rem;
  left: 100%;
  z-index: 9;
}
.PopoverActions .message-body .media .media-content div {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.PopoverActions .message-body .media .media-content div:hover {
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  cursor: pointer;
}
.PopoverActions .message-body .media .media-content div button {
  background-color: transparent;
  border: none;
}
.upAndDownButton {
  margin: 0;
  padding: 0;
  border-color: transparent;
  height: max-content;
  background: transparent;
  border-radius: 0;
}
.upAndDownButton:focus {
  outline: none !important;
}
.upAndDownButton .icon:hover {
  color: var(--info-color) !important;
}
</style>
