<template>
  <PageWrapperWithSidebar>
    <Loading :loading="loading" :loadingText="loadingText" />
    <div class="is-flex">
      <NavbarSecondary />
      <div class="blnavsecondary pb-0" style="width: 100%">
        <NavbarMain :showLogo="false" />
        <div class="columns ml-2 mb-0">
          <div class="column" style="position: relative">
            <MainItineraryContent
              :itinerary="itinerary"
              :loadServicesItinerary="loadServicesItinerary"
              class="mb-6 mt-4"
            />
            <ViewContractSalesManualModal />
          </div>
        </div>
        <div class="mx-4">
          <router-view
            :setLoading="setLoading"
            :loadItinerary="loadItinerary"
            :loadCurrencies="loadCurrencies"
            :loadLanguages="loadLanguages"
            :loadClients="loadClients"
            :loadClientContracts="loadClientContracts"
            :loadPassengersItinerary="loadPassengersItinerary"
            :loadPricesItinerary="loadPricesItinerary"
            :loadServicesItinerary="loadServicesItinerary"
            :loadServiceTypes="loadServiceTypes"
            :loadServicesTags="loadServicesTags"
            :loadItineraryDays="loadItineraryDays"
            :loadPublishedItinerary="loadPublishedItinerary"
            :validateFunctionExecution="validateFunctionExecution"
          ></router-view>
        </div>
      </div>
    </div>
  </PageWrapperWithSidebar>
</template>
<script>
import ViewContractSalesManualModal from "@/components/_shared/ViewContractSalesManualModal.vue";
import PageWrapperWithSidebar from "@/components/_shared/PageWrapperWithSidebar.vue";
import MainItineraryContent from "@/components/_shared/MainItineraryContent.vue";
import NavbarSecondary from "@/components/_shared/NavbarSecondary.vue";
import NavbarMain from "@/components/_shared/NavbarMain.vue";
import Loading from "@/components/_shared/Loading.vue";
import { PATH_INFO } from "@/router/path";
import { mapGetters } from "vuex";
import { DEFAULT_ERROR_MESSAGE } from "@/constants/request";

export default {
  name: "Quote",
  components: {
    PageWrapperWithSidebar,
    Loading,
    NavbarSecondary,
    MainItineraryContent,
    NavbarMain,
    ViewContractSalesManualModal,
  },
  computed: {
    ...mapGetters({
      itinerary: "itinerary/getItinerary",
      loading: "utils/getLoading",
      loadingText: "utils/getLoadingText",
    }),
  },
  beforeRouteLeave(to, from, next) {
    next();
    this.resetCurrentItinerary();
  },
  created() {
    this.loadTags();
    this.loadItinerariesTags();
  },
  methods: {
    setLoading(value = false) {
      this.$store.dispatch("utils/setLoading", value);
      if (!value) {
        this.setLoadingText("Loading content, please wait...");
      }
    },
    setLoadingText(value) {
      this.$store.dispatch("utils/setLoadingText", value);
    },
    resetCurrentItinerary() {
      this.$store.dispatch("itinerary/resetItineraryAndRelatedData");
    },
    async loadItinerary(uuid = null, force = false) {
      return await this.validateFunctionExecution(
        async () => {
          const idToSet = uuid || this.$route.query.itinerary;
          const response = await this.$store.dispatch("itinerary/read", {
            uuid: idToSet,
            force,
          });
          return response;
        },
        () => {
          this.$router.push(PATH_INFO.NOT_FOUND.path);
        }
      );
    },
    async loadCurrencies() {
      return await this.validateFunctionExecution(async () => {
        return await this.$store.dispatch("currency/getCurrencies", {
          page: 1,
        });
      });
    },
    async loadLanguages() {
      return await this.validateFunctionExecution(async () => {
        return await this.$store.dispatch("language/getLanguages", {
          page: 1,
        });
      });
    },
    async loadTags() {
      return await this.validateFunctionExecution(async () => {
        return await this.$store.dispatch("tags/list", {
          page: 1,
        });
      });
    },
    async loadClients() {
      return await this.validateFunctionExecution(async () => {
        return await this.$store.dispatch("client/getClients", {
          page: 1,
        });
      });
    },
    async loadClientContracts(clientId) {
      return await this.validateFunctionExecution(async () => {
        return await this.$store.dispatch("client/getClientContracts", {
          id: clientId,
          contract_id: this.itinerary.data
            ? this.itinerary.data.contract_id
            : "",
        });
      });
    },
    async loadPassengersItinerary(payload = null) {
      return await this.validateFunctionExecution(async () => {
        if (payload)
          return await this.$store.dispatch(
            "itinerary/list_passengers",
            payload
          );
      });
    },
    async loadPricesItinerary(reload = false) {
      return await this.validateFunctionExecution(async () => {
        if (this.$route.query.itinerary) {
          return await this.$store.dispatch("itinerary/list_prices", {
            uuid: this.$route.query.itinerary,
            reload,
          });
        }
      });
    },
    async loadServicesItinerary(payload = null) {
      return await this.validateFunctionExecution(async () => {
        if (payload) {
          return await this.$store.dispatch("itinerary/list_services", payload);
        }
      });
    },
    async loadServiceTypes() {
      return await this.validateFunctionExecution(async () => {
        return await this.$store.dispatch("service_types/list", {
          page: 1,
          query: "",
        });
      });
    },
    async loadServicesTags() {
      return await this.validateFunctionExecution(async () => {
        return await this.$store.dispatch("tags/getServicesTags", {
          page: 1,
          query: "",
          limit: 1000,
        });
      });
    },
    async loadItinerariesTags() {
      return await this.validateFunctionExecution(async () => {
        return await this.$store.dispatch("tags/getItinerariesTags", {
          page: 1,
          query: "",
          limit: 1000,
        });
      });
    },
    async loadItineraryDays(reload = true) {
      return await this.validateFunctionExecution(async () => {
        if (this.itinerary.data && this.$route.query.itinerary) {
          return await this.$store.dispatch("itineraryDays/getItineraryDays", {
            uuid: this.$route.query.itinerary,
            reload,
          });
        }
      });
    },
    async loadPublishedItinerary() {
      return await this.validateFunctionExecution(async () => {
        if (this.itinerary.data && this.$route.query.itinerary) {
          return await this.$store.dispatch(
            "itinerary/getPublishedConfigAndImages",
            this.$route.query.itinerary
          );
        }
      });
    },
    async validateFunctionExecution(functionToHandle = null, onError = null) {
      try {
        return await functionToHandle();
      } catch (error) {
        if (onError) onError();
        const errorMessage =
          error?.response?.data?.errors?.data || DEFAULT_ERROR_MESSAGE;
        this.setLoading(false);
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: errorMessage,
          position: "is-top",
          type: "is-danger",
          pauseOnHover: true,
        });
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
