<template>
  <b-navbar class="mb-5">
    <template #brand>
      <b-navbar-item
        tag="router-link"
        :to="{ path: paths.HOME.path }"
        v-if="showLogo"
      >
        <img src="/img/logo-degradient.svg" alt="Logo" width="110" />
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-navbar-item
            v-if="hasViewNewExperienceLinkPermission"
            tag="router-link"
            :to="{ path: paths.EXPERIENCES.path }"
            class="mr-5 has-text-weight-semi-bold is-6 grey-principal pb-0 is-relative"
          >
            <div style="position: absolute; top: -1.5rem; right: -1.25rem">
              <b-tooltip
                label="New Experiences are available!"
                type="is-dark"
                position="is-bottom"
                class="new__tag"
              >
                <p>NEW!</p>
              </b-tooltip>
            </div>
            {{ paths.EXPERIENCES.name }}
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            :to="{ path: paths.ITINERARIES.path }"
            class="mr-5 has-text-weight-semi-bold is-6 grey-principal pb-0"
            >{{ paths.ITINERARIES.name }}</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            :to="{ path: paths.QUOTES.path }"
            class="mr-5 has-text-weight-semi-bold is-6 grey-principal pb-0"
            >Quotes</b-navbar-item
          >
          <b-navbar-item
            tag="router-link"
            :to="getCurrentLocation()"
            class="mr-5 has-text-weight-semi-bold is-6 grey-principal pb-0"
            :data-test-id="constants.DATA_TEST_ID_VALUES.NEW_QUOTE_LINK"
            ><span @click="openQuoteSidebar">New Quote</span>
          </b-navbar-item>
          <b-navbar-item
            v-if="hasViewNewExperienceLinkPermission"
            tag="router-link"
            :to="{ path: paths.BASIC_INFO.path, query: { type: 'experience' } }"
            class="mr-5 has-text-weight-semi-bold is-6 grey-principal pb-0"
            ><span>New Experience</span>
          </b-navbar-item>
          <b-tooltip
            position="is-bottom"
            label="Clear cache and hard refresh"
            type="is-warning"
          >
            <button
              style="background-color: transparent; border: none"
              @click="onHardRefreshClick"
            >
              <IconCacheRefresh class="icon color-9" />
            </button>
          </b-tooltip>
          <b-dropdown
            position="is-bottom-left"
            append-to-body
            aria-role="menu"
            class="ml-4"
          >
            <template #trigger>
              <a class="avatar-box mr-3" role="button">
                <span class="name mr-2">{{ userData.name }} </span>
                <picture>
                  <img
                    class="avatar is-rounded"
                    src="/img/user-avatar.png"
                    alt="Profile avatar"
                  />
                  <ProUserSign v-if="isProUser" />
                </picture>
              </a>
            </template>
            <b-dropdown-item>
              <b-navbar-item
                tag="router-link"
                :to="{ path: paths.PROFILE.path }"
              >
                Profile
              </b-navbar-item>
            </b-dropdown-item>
            <b-dropdown-item @click="redirectToResetPassword">
              Change password
            </b-dropdown-item>
            <hr class="dropdown-divider" />
            <b-dropdown-item @click="handleLogout">Logout</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from "vuex";
import ProUserSign from "./ProUserSign.vue";
import { PATH_INFO } from "@/router/path";
import { DATA_TEST_ID_VALUES } from "@/constants/test";
import { USER_ROLE } from "@/constants";
import helpers from "@/helpers";

export default {
  name: "NavbarMain",
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      paths: { ...PATH_INFO },
      constants: { DATA_TEST_ID_VALUES },
    };
  },
  computed: {
    ...mapGetters({
      authData: "auth/getAuthData",
    }),
    userData() {
      return this.authData.user;
    },
    isProUser() {
      return this.authData.user_type.name === "pro";
    },
    hasViewNewExperienceLinkPermission() {
      return !helpers.hasAnyRoles(
        this.authData.user,
        [USER_ROLE.TRAVEL_AGENT],
        false
      );
    },
  },
  methods: {
    getCurrentLocation() {
      return {
        path: this.$route.path,
        query: this.$route.query,
      };
    },
    openQuoteSidebar() {
      this.$store.dispatch("utils/setShowSideBar", true);
    },
    redirectToResetPassword() {
      this.$router.push({ path: PATH_INFO.SEND_RESET_PASSWORD.path });
    },
    async handleLogout() {
      await this.$store.dispatch("auth/logout", { redirect: true });
      this.$store.dispatch("utils/hardRefresh", {
        refreshAuthData: false,
      });
    },
    onHardRefreshClick() {
      this.$store.dispatch("utils/hardRefresh", {
        refreshAuthData: true,
      });
    },
  },
  components: { ProUserSign },
};
</script>
<style scoped>
a:hover {
  color: var(--info-color) !important;
}
picture {
  position: relative;
  max-height: 2.5rem;
  max-width: 2.5rem;
  display: inline-block;
}
picture > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sign__container {
  bottom: -0.75rem;
  /* left: -25%; */
  transform: translateX(-20%);
}
</style>
